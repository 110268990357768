export const piedEnergy = [
  { x: "Renewables Energy", value: 637166, fill: "#ed7d31" },
  { x: "Generated Energy", value: 721630, fill: "#bf9001" },
  { x: "Energy Loads", value: 148662, fill: "#f8cfb2" },
];

export const piedRenewables = [
  { x: "On-site Solar", value: 637166, fill: "#c9e0ba" },
  { x: "Off-site Renewable", value: 721630, fill: "#5d8f3b" },
  { x: "REC Purchase", value: 78662, fill: "#384c29" },
];
